<template>
  <div class="page" v-if="load">
    <div class="d-flex justify-content-center">
      <div class="spinner-border text-success" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  <div class="page" v-if="!load">
    <invoice-header :key="headKey + allData.invoice_status" :status="allData.invoice_status"
      :hidden="hiddenFields" @save="saveData" @delete="deleteData"
      @saveAndLock="saveAndLock" @newNormal="createNormal" @newAdvance="createAdvance" @print="print" @eInvoice="eInvoice"
      @fiscal="fiscal"></invoice-header>

    <div class="row" style="overflow: unset">
      <div class="col-4 company pt-1">
        <b>PRIMERO RENT A CAR DOO BEOGRAD</b><br />
        Vase Pelagića 38<br />
        11040 Beograd<br />
        SERBIA<br />
        Tel: +381 11 785 27 88; 785 27 89<br />
        Fax: +381 11 212 9486<br />
        PIB (Tax ID number): <b>SR102449114</b><br />
        Matični broj / Register Co. number: <b>17453416</b><br />
        Tekući račun (RSD) / Bank account (RSD): <b>160-305895-11</b><br />
        SWIFT: <b>DBDBRSBG</b><br />
        IBAN: <b>RS35160005010027484921</b><br />
        BancaIntesa AD, Milentija Popovića 7B, 11070 Beograd<br />
        <div class="fs-6 text-success">
          {{
            this.invoice != null && this.invoice.ref_doc_nr
            ? "Po dokumentu:"
            : ""
          }}
          <b>{{
            this.invoice != null && this.invoice.ref_doc_nr
            ? this.invoice.ref_doc_nr
            : ""
          }}</b>
        </div>
        <div class="fs-6 text-success" v-if="this.invoice != null && this.invoice.reservation_id">
          {{
            this.invoice != null && this.invoice.reservation_id
            ? "Outbound:"
            : ""
          }}
          <router-link :to="`/reservation/${this.invoice.reservation_id}`" target="_blank"><b>{{
            this.invoice != null && this.invoice.reservation_id
            ? this.invoice.Reservation.reservation_num
            : ""
          }}</b></router-link>
        </div>
        <div class="fs-6 text-success" v-if="this.invoice != null && this.invoice.cession_id">
          {{
            this.invoice != null && this.invoice.cession_id
            ? "Po cesiji:"
            : ""
          }}
          <router-link :to="`/cessions/${this.invoice.cession_id}`" target="_blank"><b>{{
            this.invoice != null && this.invoice.cession_id
            ? this.invoice.cession_id
            : ""
          }}</b>
          </router-link>
        </div>
        <div class="fs-6 text-success" v-if="this.invoice != null && this.invoice.selling_id">
          {{
            this.invoice != null && this.invoice.selling_id
            ? "Prodaja"
            : ""
          }}
          <router-link :to="`/sellings/${this.invoice.selling_id}`" target="_blank"><b>{{
            this.invoice != null && this.invoice.selling_id
            ? this.invoice.selling_id
            : ""
          }}</b>
          </router-link>
        </div>
        <div class="fs-6 text-success">
          {{
            this.invoice != null && this.invoice.saop_num ? "SAOP Broj:" : ""
          }}
          <b>{{
            this.invoice != null && this.invoice.saop_num
            ? this.invoice.saop_num
            : ""
          }}</b>
        </div>
        <div class="fs-6 text-success">
          {{
            this.invoice != null && this.invoice.sef_num ? "SEF Broj:" : ""
          }}
          <b>{{
            this.invoice != null && this.invoice.sef_num
            ? this.invoice.sef_num
            : ""
          }}</b>
        </div>
        <div class="fs-6 text-danger">
          {{
            this.invoice != null && this.invoice.saop_st_num
            ? "SAOP Storno Broj:"
            : ""
          }}
          <b>{{
            this.invoice != null && this.invoice.saop_st_num
            ? `${this.invoice.saop_st_num} od ${formatDate(this.invoice.storno_date)}`
            : ""
          }}</b>
        </div>
      </div>
      <div class="col-4" style="overflow: unset">
        <data-form2 :key="dataInvoice['doc_nr'] + 'head' + nextKey" :data="dataInvoiceHead" :formFields="formHeadFields"
          @changed="inputChanged" :readonly="true" minheight="1px" :nooverflow="true" />
      </div>
      <div class="col-4" style="overflow: unset">
        <data-form2 :key="dataInvoice['doc_nr'] + 'head2' + excKey" :data="dataInvoiceHead2" :formFields="formHeadFields2"
          @changed="inputChanged" @nbs="getFromNBS" :readonly="true" minheight="1px" :nooverflow="true" />
      </div>

      <div class="card mb-3" style="overflow: unset" v-if="!!aditionalFields.rows">
        <data-form2 :key="'additional' + nextKey" :data="aditionalData" :formFields="aditionalFields"
          @changed="inputChanged" @save="saveAdditional" :readonly="true" minheight="1px" :nooverflow="true" />
      </div>
    </div>
    <!-- <div class="table-responsive"> -->
    <table class="table table-bordered table-sm table-striped table-hover" :key="tableKey">
      <tbody>
        <tr v-if="dataInvoice.invoice_status == 'New'">
          <th colspan="6">
            <data-form2 :key="dataInvoice['doc_nr'] + 'item' + nextKey" :data="itemData" :formFields="formFields"
              @save="addItem" @changed="inputChanged" :readonly="true" minheight="1px" :flex="true" />
          </th>
        </tr>
        <tr v-else>
          <th class="col-md-1 text-center" scope="row"></th>
          <th class="col-md-5">Artikal</th>
          <th class="col-md-1 text-center">Kol</th>
          <th class="col-md-2 text-end">
            <nobr>Cena RSD {{ sellType == "MP" ? "(bruto)" : "(neto)" }}</nobr>
          </th>
          <th class="col-md-2 text-end">
            <nobr>Cena u valuti {{ sellType == "MP" ? "(bruto)" : "(neto)" }}</nobr>
          </th>
          <th class="col-md-1 text-center"></th>
        </tr>
        <tr v-for="(item, index) in itemsList" :key="'item' + (item['id'] ?? index)">
          <th class="col-md-1 text-center" scope="row">
            {{ index + 1 }}
          </th>
          <td class="col-md-5" v-if="item['article']">
            {{ item["article"].name }}
          </td>
          <td class="col-md-1 text-center">{{ formatNumber(item["quantity"]) }}</td>
          <td v-if="dataInvoice.invoice_status == 'New'" class="col-md-2 text-end">
            <input :key="`editprice_${item['id']}`" type="number" style="text-align: right" class="form-control"
              :id="`editprice_${item['id']}`" v-model="item['price']"
              @blur="inputChanged(item['price'], `editprice_${item['id']}`)" />
          </td>
          <td v-else class="col-md-2 text-end">{{ formatNumber(item["price"]) }}</td>
          <td v-if="dataInvoice.invoice_status == 'New'" class="col-md-2 text-end">
            <input :key="`editprice_exc_${item['id']}`" type="number" style="text-align: right" class="form-control"
              :id="`editprice_exc_${item['id']}`" v-model="item['price_exc']" @blur="
                inputChanged(item['price_exc'], `editprice_exc_${item['id']}`)
              " />
          </td>
          <td v-else class="col-md-2 text-end">{{ formatNumber(item["price_exc"]) }}</td>
          <td class="col-md-1 text-center">
            <button v-if="dataInvoice.invoice_status == 'New'" class="btn btn-sm btn-danger bi bi-x" type="button"
              @click="removeItem(index)"></button>
          </td>
        </tr>
        <tr v-for="(vat, index) in tableSum" :key="index">
          <th class="col-md-1 text-center">#</th>
          <td class="col-md-7" colspan="2" v-if="
            vat['label'].startsWith('PDV') ||
            vat['label'].startsWith('Osnovica')
          ">
            {{ vat["label"] }}
          </td>
          <th class="col-md-7" colspan="2" v-else>
            {{ vat["label"] }}
          </th>
          <td class="col-md-2 text-end" v-if="
            vat['label'].startsWith('PDV') ||
            vat['label'].startsWith('Osnovica')
          ">
            {{ formatNumber(vat["sum"]) }}
          </td>
          <th class="col-md-2 text-end" v-else>
            {{ formatNumber(vat["sum"]) }}
          </th>
          <td class="col-md-2 text-end" v-if="
            vat['label'].startsWith('PDV') ||
            vat['label'].startsWith('Osnovica')
          ">
            {{ formatNumber(vat["sum_exc"]) }}
          </td>
          <th class="col-md-2 text-end" v-else>{{ formatNumber(vat["sum_exc"]) }}</th>
          <td class="col-md-1"></td>
        </tr>
        <tr class="bg-secondary" v-if="paymentsList.length > 0">
          <td colspan="6"></td>
        </tr>
        <tr v-for="(item, index) in paymentsList" :key="'payment' + (item['id'] ?? index)">
          <th class="col-md-1 text-center" scope="row">#</th>
          <td class="col-md-7">Uplata: {{ item["statement_nr"] }}</td>
          <td class="col-md-2 text-center">
            {{ dateFormat(item["statement_date"] ?? "") }}
          </td>
          <td class="col-md-2 text-end">{{ formatNumber(item["payment"]) }}</td>
          <td class="col-md-2 text-end">{{ formatNumber(item["payment_exc"]) }}</td>
          <td class="col-md-1 text-center">
            <div class="d-flex justify-content-center" v-if="!item['advance_id']">
              <button v-if="
                !(
                  this.invoice &&
                  this.invoice.invoice_type == 'Advance' &&
                  this.invoice.invoice_status != 'New'
                )
              " class="btn btn-sm btn-danger bi bi-x" type="button" @click="removePayment(index)"></button>
              <button v-if="(
                  this.invoice &&
                  this.invoice.invoice_type == 'Proform' &&
                  this.invoice.invoice_status == 'Done'
                )"
                class="btn btn-success bi bi-journal-plus ms-2 btn-sm text-nowrap" type="button" @click="createAdvance(index)">
                Kreiraj Avans
              </button>
            </div>
            <div class="d-flex justify-content-center" v-else>
              <router-link class="btn btn-success bi bi-journal-plus ms-2 btn-sm text-nowrap" :to="`/invoice/Advance/${item['advance_id']}`" target="_blank">
                Otvori Avans
              </router-link>
            </div>
          </td>
        </tr>

        <tr v-for="(vat, index) in Object.values(tableTotalSum)" :key="index">
          <th class="col-md-1 text-center">#</th>
          <th class="col-md-7" colspan="2">
            {{ vat["label"] }}
          </th>
          <th class="col-md-2 text-end">{{ formatNumber(vat["sum"]) }}</th>
          <th class="col-md-2 text-end">{{ formatNumber(vat["sum_exc"])}}</th>
          <td class="col-md-1"></td>
        </tr>
      </tbody>
    </table>
    <div v-if="
      !(
        this.invoice &&
        this.invoice.invoice_type == 'Advance' &&
        this.invoice.invoice_status != 'New'
      )
    ">
      <button class="btn btn-sm btn-warning bi bi-plus" type="button" @click="toggle = !toggle">
        Uplata
      </button>
      <Transition>
        <div v-show="toggle" class="bg-secondary bg-opacity-10 p-2 mt-2">
          <data-form2 :key="'pay' + paymentFormKey" :data="paymentData" :formFields="paymentFields"
            @save="addPaymentAndSave" @changed="inputChanged" :readonly="true" minheight="1px" :flex="true" />
        </div>
      </Transition>
    </div>
    <!-- </div> -->
    <data-form2 :key="dataInvoice['doc_nr'] + 'comments' + commKey" :data="dataComments" :formFields="formCommentsFields"
      @changed="inputChanged" :readonly="true" />
    <form-futher :key="invoice['id'] + 'foother'" v-if="invoice" :createdBy="invoice['createdBy']"
      :created="invoice['createdAt']" :updatedBy="invoice['updatedBy']" :updated="invoice['updatedAt']" />
  </div>
</template>

<script>
import DataForm2 from "../widgets/DataForm2.vue";
import FormFuther from "../users/FormFuther.vue";
import InvoiceDataService from "../../services/invoice.service";
import InvoiceHeader from "./header/InvoiceHeader";
import ExchangeRatesDataService from "../../services/exchange-rates.service";
import StationsDataService from "../../services/stations.service";
import CustomersDataService from "../../services/customers.service";
import SefEnabledCustomersDataService from "../../services/sef-customers.service";
import ArticlessDataService from "../../services/articles.service";
import dateUtils from "../../utils/date-common";
import invoiceUtils from "./invoice-utils.js";
import exchangeUtils from "../exchange-rates/exchange-utils.js";
import utils from "../../utils/common";
import SelectAdvance from "./SelectAdvance.vue";
// import PaymentTypesForm from "./teron/PaymentTypesForm.vue";

export default {
  name: "invoice-form",
  props: [],
  components: {
    DataForm2,
    InvoiceHeader,
    FormFuther,
  },
  data() {
    return {
      invoice: null,
      itemsList: [],
      paymentsList: [],
      toggle: false,
      message: "",
      nextKey: 0,
      headKey: 0,
      tableKey: 0,
      commKey: 0,
      paymentFormKey: 0,
      priceKey: 0,
      priceExcKey: 0,
      paymentKey: 0,
      paymentExcKey: 0,
      excKey: 0,
      sell_type: "VP",
      stationsList: [],
      customersList: [],
      articlesList: [],
      load: false,
      sef: null,
    };
  },
  computed: {
    taxRates() {
      return this.$store.state.app.taxRates;
    },
    hiddenFields() {
      const hidden = [];
      if ( this.invoice && this.invoice.saop_num ) hidden.push('fiscal');
      if ( this.invoice && this.invoice.invoice_payments && this.invoice.invoice_payments.length > 0 && this.invoice.invoice_payments.find((p) => !!p.advance_id)) hidden.push('advance');
      return hidden;
    },
    formFields() {
      return {
        rows: [
          [{ id: { type: "hidden" } }],
          [
            { space: { type: "hidden", width: "100px" } },
            {
              article_id: {
                label: "Artikal",
                width: this.paymentsList.length > 0 ? "570px" : "520px",
                type: "dropdown",
                // url: "/articles?limit=100000&ascending=1",
                list: this.articlesList.map((a) => {
                  return {
                    id: a.id,
                    name: `${a.name} - ${a["tax-rate"] ? a["tax-rate"]["rate"] + "%" : ""
                      }  ${a.serial_nr ? "- " + a.serial_nr : ""}`,
                  };
                }),
              },
            },
            // ],
            // [
            {
              quantity: {
                type: "number",
                label: "Kolicina",
                width: this.paymentsList.length > 0 ? "170px" : "110px",
                align: "text-center",
              },
            },
            {
              price: {
                type: "number",
                label:
                  this.sellType == "MP"
                    ? "Cena RSD (bruto)"
                    : "Cena RSD (neto)",
                key: "price" + this.priceKey,
                // width: "160px",
                align: "text-end",
              },
            },
            {
              price_exc: {
                type: "number",
                label:
                  this.sellType == "MP"
                    ? "Cena u valuti (bruto)"
                    : "Cena valuti (neto)",
                key: "price" + this.priceExcKey,
                // width: "160px",
                align: "text-end",
              },
            },
            // { space2: { type: "hidden", width: "10px" } },
            {
              add: {
                type: "button",
                // text: "Dodaj",
                width: this.paymentsList.length > 0 ? "60px" : "75px",
                emit: "save",
                biIcon: "bi-plus",
              },
            },
          ],
        ],
      };
    },
    formHeadFields() {
      return {
        rows: [
          [{ id: { type: "hidden" } }],
          [
            {
              doc_date: {
                label: "Datum racuna",
                type: "date",
                disabled: this.invoice && (this.invoice.invoice_status == "Done" || this.invoice && this.invoice.invoice_status == "Deleted"),
              },
            },
          ],
          [
            {
              doc_nr: {
                label: "Broj racuna",
                type: "text",
                disabled: true,
                description:
                  "Broj računa će biti dodeljen kada se dokument snimi i zaključa",
              },
            },
          ],
          [
            {
              stations_id: {
                label: "Poslovnica",
                labelField: "code",
                type: "dropdown",
                // url: "/stations?limit=100000&ascending=1",
                list: this.stationsList,
                disabled: this.invoice && (this.invoice.invoice_status == "Done" || this.invoice && this.invoice.invoice_status == "Deleted"),
              },
            },
          ],
          [
            {
              customers_id: {
                label: "Kupac",
                type: "dropdown",
                // url: "/customers?limit=100000&ascending=1",
                list: this.customersList.map((c) => {
                  return {
                    id: c.id,
                    name: `${c.name} ${c.type == 3 ? "- " + c.phone : ""}`,
                  };
                }),
                blockquote: `${this.allData != null && this.allData.customers_id ? (this.sef && this.sef.data.pib ? '' : 'Nije u sistemu eFaktura!') : ''}`,
                disabled: this.invoice && (this.invoice.invoice_status == "Done" || this.invoice && this.invoice.invoice_status == "Deleted"),
              },
            },
          ],
          [
            {
              cost_unit_id: {
                type: this.invoice && this.invoice.invoice_type != "Normal" ? 'hidden' : null,
                label: "Registarski Broj",
                disabled: this.invoice && (this.invoice.invoice_status == "Done" || this.invoice && this.invoice.invoice_status == "Deleted"),
              },
            },
          ],
        ],
      };
    },
    formHeadFields2() {
      return {
        rows: [
          [
            {
              exchange_date: {
                label: "Datum kursa",
                type: "date",
                max: new Date().toISOString().substring(0, 10),
                description:
                  "Ukoliko ne postoji kurs za izabrani datum, možeta ga dodati tasterom NBS, ili korišćenjem forme Kursne liste",
                disabled: this.invoice && (this.invoice.invoice_status == "Done" || this.invoice && this.invoice.invoice_status == "Deleted"),
              },
            },
          ],
          [
            {
              exchange: {
                label: "Valuta",
                type: "dropdown",
                dropdownHeight: "150px",
                list: [
                  { id: "eur", name: "EUR" },
                  { id: "usd", name: "USD" },
                  { id: "aud", name: "AUD" },
                  { id: "chf", name: "CHF" },
                  { id: "gbp", name: "GBP" },
                  { id: "sek", name: "SEK" },
                  { id: "eur_adiko", name: "EUR Adiko" },
                  { id: "usd_adiko", name: "USD Adiko" },
                  { id: "aud_adiko", name: "AUD Adiko" },
                  { id: "chf_adiko", name: "CHF Adiko" },
                  { id: "gbp_adiko", name: "GBP Adiko" },
                  { id: "sek_adiko", name: "SEK Adiko" },
                ],
                disabled: this.invoice && (this.invoice.invoice_status == "Done" || this.invoice && this.invoice.invoice_status == "Deleted"),
              },
            },
            {
              exchange_value: {
                label: "Kurs",
                type: "number",
                key: "exc" + this.excKey,
                disabled: this.invoice && (this.invoice.invoice_status == "Done" || this.invoice && this.invoice.invoice_status == "Deleted"),
              },
            },
          ],
          [{ val_date: 
            { label: this.invoice && this.invoice.invoice_type == "Advance" ? "Datum valute" : "Datum dospeća", 
              type: "date", 
              disabled: this.invoice && (this.invoice.invoice_status == "Done" || this.invoice && this.invoice.invoice_status == "Deleted"), 
            } 
          }],
          [
            {
              po_num: {
                label: "Broj porudzbenice",
                type: "text",
                disabled: this.invoice && (this.invoice.invoice_status == "Done" || this.invoice && this.invoice.invoice_status == "Deleted"),
              },
            },
          ],
          this.invoice && this.invoice.reservation_id ? [
            {
              voucher_num: {
                label: "Broj Vaucera",
                disabled: this.invoice && (this.invoice.invoice_status == "Done" || this.invoice && this.invoice.invoice_status == "Deleted"),
              },
            },
          ] : this.invoice && this.invoice.selling_id ? [] : [
            {
              reservation_num: {
                label: "Broj rezervacije",
                disabled: this.invoice && (this.invoice.invoice_status == "Done" || this.invoice && this.invoice.invoice_status == "Deleted"),
              },
            },
            {
              voucher_num: {
                label: "Broj Vaucera",
                disabled: this.invoice && (this.invoice.invoice_status == "Done" || this.invoice && this.invoice.invoice_status == "Deleted"),
              },
            },
          ],
        ],
      };
    },
    formCommentsFields() {
      return {
        rows: [
          [{ divider: { type: "divider" } }],
          [
            {
              comment: {
                label: "Komentar za stampu",
                type: "textarray",
                rows: 4,
                disabled: this.invoice && (this.invoice.invoice_status == "Done" || this.invoice && this.invoice.invoice_status == "Deleted"),
              },
            },
          ],
          [
            {
              comment2: {
                label: "Komentar koji se ne stampa",
                type: "textarray",
              },
            },
          ],
        ],
      };
    },
    dataInvoice() {
      return this.invoice
        ? {
          id: this.invoice.id,
          invoice_status: this.invoice.invoice_status,
          invoice_type: this.invoice.invoice_type,
        }
        : {
          invoice_status: "New",
          invoice_type: this.$route.params.type,
        };
    },
    dataInvoiceHead() {
      return this.invoice
        ? {
          doc_date: this.invoice.storno_date ?? this.invoice.doc_date,
          doc_nr: this.invoice.doc_nr,
          customers_id: this.invoice.customers_id,
          stations_id: this.invoice.stations_id,
          cost_unit_id: this.invoice.cost_unit_id,
        }
        : {
          doc_date: new Date().toISOString().substring(0, 10),
          doc_nr: "",
        };
    },
    dataInvoiceHead2() {
      // console.log({ route: this.$route.params.id, invoice: this.invoice });
      return this.$route.params.id != 0
        ? this.invoice
          ? {
            exchange_date: this.invoice.exchange_date,
            exchange: this.invoice.exchange,
            exchange_value: this.invoice.exchange_value,
            val_date: this.invoice.val_date,
            po_num: this.invoice.po_num,
            reservation_num: this.invoice.reservation_num,
            voucher_num: this.invoice.voucher_num,
          }
          : {}
        : {
          exchange_date: new Date().toISOString().substring(0, 10),
          exchange: "eur",
          exchange_value: this.getExchange(new Date()),
          val_date: new Date().toISOString().substring(0, 10),
        };
    },
    itemData() {
      return { quantity: 1 };
    },
    paymentData() {
      return {
        statement_nr: null,
        statement_date: new Date().toISOString().substring(0, 10),
        payment: null,
        payment_exc: null,
      };
    },
    paymentFields() {
      return {
        rows: [
          [{ id: { type: "hidden" } }],
          [
            {
              statement_nr: {
                type: "text",
                label: "Broj izvoda",
              },
            },
            {
              statement_date: {
                type: "date",
                label: "Datum uplate",
                max: new Date().toISOString().substring(0, 10),
              },
            },
            {
              payment: {
                type: "number",
                label: "Uplaceno RSD",
                key: "payment" + this.paymentKey,
              },
            },
            {
              payment_exc: {
                type: "number",
                label: "Uplaćeno u valuti",
                key: "payment" + this.paymentExcKey,
              },
            },
            {
              add: {
                type: "button",
                emit: "save",
                biIcon: "bi-plus",
              },
            },
          ],
        ],
      };
    },
    dataComments() {
      return this.invoice
        ? { comment: this.invoice.comment, comment2: this.invoice.comment2 }
        : {
          comment: '' // `Dinarski iznos ove fakture služi za utvrđivanje PDV-a.\nPlaćanje se vrši po ugovornim obavezama, po srednjem kursu NBS na dan $datum_kursa. \n\nMolimo Vas da uplatu izvršite na tekući račun broj 170-00300251280000-93, UniCredit Bank Srbija A.D.`,
        };
    },
    aditionalFields() {
      if (!this.invoice || !this.invoice.additional) {
        return {};
      }
      const items = Object.keys(
        typeof this.invoice.additional == "string"
          ? JSON.parse(this.invoice.additional)
          : this.invoice.additional
      ).map((k) => {
        const res = {};
        res[k] = utils.additionalLabel(k);
        return res;
      });
      const sortingArr = ['contract_nr','km','driver','pickup_date','return_date', 'registration_nr', 'model', 'wheels_nr'];
      items.sort(function(a, b){  
        // console.log({a, b, order: sortingArr.indexOf(Object.keys(a)[0]) - sortingArr.indexOf(Object.keys(b)[0])});
        return sortingArr.indexOf(Object.keys(a)[0]) - sortingArr.indexOf(Object.keys(b)[0]);
      });
      const emptyPlaces =
        items.length < 6 ? [...Array(6 - items.length).fill({})] : [null];
      if (items.length == 0) return {};
      const rowsRes = items.length < 6
        ? {
          rows: [[...items, ...emptyPlaces]],
        }
        : items.length == 9
          ? {
            rows: [
              [...items.slice(0, 2), {}, {}, {}, {}],
              [...items.slice(2, 6), {}, {}],
              [...items.slice(6, 9), {}, {}, {}],
            ],
          }
          : {
            rows: [
              [...items.slice(0, 4), {}, {}],
              [
                ...items.slice(4, 7),
                this.dataInvoice.invoice_status == "Done"
                  ? {
                    add: {
                      type: "button",
                      emit: "save",
                      biIcon: "bi-save",
                      text: " Sačuvaj",
                    },
                  }
                  : {},
                {},
                {},
              ],
            ],
          };
          // if we have wheels_nr we need to add one more row with this field
          if (items.length >= 6 && items.find(i => Object.keys(i)[0] == 'wheels_nr')) {
            rowsRes.rows.push([{ wheels_nr: { label: utils.additionalLabel('wheels_nr').label, type: "text" } }, {}, {}, {}, {}, {}]);
          }
          return rowsRes;
    },
    aditionalData() {
      const ad =  this.invoice && this.invoice.additional
        ? typeof this.invoice.additional == "string"
          ? JSON.parse(this.invoice.additional)
          : this.invoice.additional
        : {};
      // console.log({ ad });
      if (ad.pickup_date == '') {
        ad.pickup_date = undefined;
      }
      if (ad.return_date == '') {
        ad.return_date = undefined;
      }
      return ad;
    },
    allData() {
      return {
        ...this.dataInvoice,
        ...this.dataInvoiceHead,
        ...this.dataInvoiceHead2,
        ...this.dataComments,
        additional: { ...this.aditionalData },
      };
    },
    tableSum() {
      return {};
    },
    tableTotalSum() {
      return {};
    },
    cachedVals() {
      return {
        price: "",
        price_exc: "",
        payment: "",
        payment_exc: "",
      };
    },
  },
  methods: {
    formatDate(date) { return dateUtils.formatDate(date) },
    formatNumber(num) { return utils.toNumberFormat(Number.parseFloat(num)) },
    inputChanged(e, name) {
      if (
        name != "article_id" &&
        name != "price" &&
        name != "price_exc" &&
        name != "quantity"
      ) {
        this.allData[name] = e;
        this.invoice[name] = e;
      }

      if (e && name == "price") {
        this.updatePrice(e);
      }

      if (e && name == "price_exc") {
        this.updateExcPrice(e);
      }

      if (e && name == "payment") {
        this.updatePayment(e);
      }

      if (e && name == "payment_exc") {
        this.updateExcPayment(e);
      }

      if (name == "exchange") {
        this.getExchange(this.dataInvoiceHead2.exchange_date);
        this.excKey++;
      }

      if (name == "exchange_date") {
        this.getExchange(e);
        this.excKey++;
      }

      if (name == "exchange_value") {
        this.updateArticlesPrices(e);
      }

      if (name == "stations_id") {
        StationsDataService.get(e)
          .then((result) => {
            this.sell_type = result.data.sale_type;
          })
          .catch((err) => {
            console.log({ err });
            // VP
          });
      }
      if (name == "customers_id") {
        // console.log({ name, e });
        const current = new Date(this.invoice.doc_date);
        const customer = this.customersList.find((c) => c.id == e);
        if (customer.pib) {
          SefEnabledCustomersDataService.get(customer.pib ?? '0').then((sef) => {
            this.sef = sef;
            if (this.sef && this.sef.data.pib) {
              if (this.invoice.comment2) {
                this.invoice.comment2 = this.invoice.comment2.replaceAll('Nije u sistemu eFaktura!\n', '');
              }
            } else {
              this.invoice.comment2 = `Nije u sistemu eFaktura!\n${this.invoice.comment2 ?? ''}`;
            }

            // this.allData.comment2 = `Nije u sistemu eFaktura!\n${this.allData.comment2 ?? ''}`;
            this.commKey++;
          });
        }
        this.invoice["val_date"] = dateUtils.toMysqlDate(
          current.setDate(current.getDate() + (customer["valuta"] ?? 10))
        );
        this.dataInvoiceHead2["val_date"] = this.invoice["val_date"];
        this.excKey++;
      }
      if (name.startsWith("editprice_")) {
        // console.log({ name, e, items: this.itemsList });
        const parts = name.split("_");
        const editId = parts.length == 2 ? parts[1] : parts[2];

        for (const obj of this.itemsList) {
          if (obj.id == editId) {
            if (parts.length == 2) {
              obj.price = e;
              obj.price_exc = Number.parseFloat(
                e / this.allData.exchange_value
              ).toFixed(2);
            } else {
              obj.price_exc = e;
              obj.price = Number.parseFloat(
                e * this.allData.exchange_value
              ).toFixed(2);
            }

            const tax = this.taxRates.find((t) => t.id == obj.article.tax);
            const taxKoef = Number.parseFloat(tax.rate) / 100 + 1;

            obj.tax =
              this.sellType == "MP"
                ? (Number.parseFloat(obj.price) * (1 - 1 / taxKoef)).toFixed(2)
                : (
                  Number.parseFloat(obj.price) *
                  (Number.parseFloat(tax.rate) / 100)
                ).toFixed(2);
            obj.tax_exc =
              this.sellType == "MP"
                ? (
                  Number.parseFloat(obj.price_exc) *
                  (1 - 1 / taxKoef)
                ).toFixed(2)
                : (
                  Number.parseFloat(obj.price_exc) *
                  (Number.parseFloat(tax.rate) / 100)
                ).toFixed(2);

            break;
          }
        }
        invoiceUtils.updateSum(this.tableSum, this.itemsList, this.taxRates);
        invoiceUtils.updateTotalSum(
          this.tableTotalSum,
          this.paymentsList,
          this.tableSum
        );
        this.tableKey++;
      }

      if (name == 'comment2') {
        if (this.invoice.invoice_status == "Done" || this.invoice.invoice_status == "Deleted") {
          this.updateComment2({ id: this.invoice.id, comment2: e });
        }
      }
    },
    getExchange(date) {
      ExchangeRatesDataService.getByDate(date).then((res) => {
        const exc = res.data[this.invoice.exchange ?? "eur"];
        this.dataInvoiceHead2["exchange_value"] = exc;
        this.allData["exchange_value"] = exc;
        this.invoice["exchange_value"] = exc;
        this.updatePrice(this.itemData.price);
        this.updateArticlesPrices(exc);
        this.excKey++;
      });
    },
    updatePrice(val) {
      invoiceUtils.updateField(
        val,
        this.cachedVals,
        this.itemData,
        "price",
        "price_exc",
        this.allData.exchange_value
      );
      this.priceExcKey++;
    },
    updateExcPrice(val) {
      invoiceUtils.updateFieldExc(
        val,
        this.cachedVals,
        this.itemData,
        "price",
        "price_exc",
        this.allData.exchange_value
      );
      this.priceKey++;
    },
    updatePayment(val) {
      invoiceUtils.updateField(
        val,
        this.cachedVals,
        this.paymentData,
        "payment",
        "payment_exc",
        this.allData.exchange_value
      );
      this.paymentExcKey++;
    },
    updateExcPayment(val) {
      invoiceUtils.updateFieldExc(
        val,
        this.cachedVals,
        this.paymentData,
        "payment",
        "payment_exc",
        this.allData.exchange_value
      );
      this.paymentKey++;
    },
    updateArticlesPrices(exc) {
      for (const obj of this.itemsList) {
        obj.price = Number.parseFloat(obj.price_exc * exc).toFixed(2);
        // obj.tax = Number.parseFloat(obj.tax_exc * exc).toFixed(2);
        const tax = this.taxRates.find((t) => t.id == obj.article.tax);
        const taxKoef = Number.parseFloat(tax.rate) / 100 + 1;
        obj.tax =
              this.sellType == "MP"
                ? (Number.parseFloat(obj.price) * (1 - 1 / taxKoef)).toFixed(2)
                : (
                  Number.parseFloat(obj.price) *
                  (Number.parseFloat(tax.rate) / 100)
                ).toFixed(2);
      }
      invoiceUtils.updateSum(this.tableSum, this.itemsList, this.taxRates);
      invoiceUtils.updateTotalSum(
        this.tableTotalSum,
        this.paymentsList,
        this.tableSum
      );
      this.tableKey++;
    },
    dateFormat(date) {
      return dateUtils.formatDate(date);
    },
    saveAndLock() {
      this.allData.invoice_status = "Done";
      this.dataInvoiceHead.invoice_status = "Done";
      this.saveData();
    },
    saveData() {
      if (!this.allData.stations_id || !this.allData.customers_id) {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: "Morate izabrati poslovnicu i kupca",
        });
        this.allData.invoice_status = "New";
        this.dataInvoiceHead.invoice_status = "New";
        return;
      }
      if (this.itemsList.length <= 0) {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: "Morate dodati bar jedan artikal u listu!",
        });
        this.allData.invoice_status = "New";
        this.dataInvoiceHead.invoice_status = "New";
        return;
      }

      if (
        this.paymentsList.length <= 0 &&
        this.invoice.invoice_type == "Advance" &&
        this.dataInvoiceHead.invoice_status == "Done"
      ) {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: "Morate uneti uplate da bi zakljucali Avans!",
        });
        this.allData.invoice_status = "New";
        this.dataInvoiceHead.invoice_status = "New";
        return;
      }

      /// for Normal, If custumer country is not Serbia, then exchange date should be same as doc date
      if (this.allData.invoice_type == 'Normal') {
        const customer = this.customersList.find((c) => c.id == this.allData.customers_id);
        if (customer && customer.country != 'Serbia' && this.allData.exchange_date != this.allData.doc_date) {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: "Za INO kupce, datum kursa mora biti isti kao datum računa!",
          });
          this.allData.invoice_status = "New";
          this.dataInvoiceHead.invoice_status = "New";
          return;
        }
        /// if article require wheels broj, then it should be entered
        /// if we have wheels_nr in additional field, hen we should check if it is entered
        if (this.aditionalFields && this.aditionalFields.rows && this.aditionalFields.rows.length > 0) {
          for(let j = 0; j < this.aditionalFields.rows.length; j++) {
            for (let i = 0; i < this.aditionalFields.rows[j].length; i++) {
              const f = this.aditionalFields.rows[j][i];
              const key = Object.keys(f)[0];
              if (key == 'wheels_nr' && !this.allData.additional[key]) {
                this.$store.commit("app/showAlert", {
                  type: "danger",
                  message: "Morate uneti Wheels broj!",
                });
                this.allData.invoice_status = "New";
                this.dataInvoiceHead.invoice_status = "New";
                return;
              }
              if (key == 'wheels_nr' && this.allData.additional[key]) {
                /// validate wheels_nr - should be like INV-nnnnnn or L-nnnnnn. max 10 chars
                // set first to UpperCase
                this.allData.additional[key] = this.allData.additional[key].toUpperCase();
                if (!/^(INV|L)-\d{1,6}$/.test(this.allData.additional[key])) {
                  this.$store.commit("app/showAlert", {
                    type: "danger",
                    message: "Wheels broj nije u ispravnom formatu!",
                  });
                  this.allData.invoice_status = "New";
                  this.dataInvoiceHead.invoice_status = "New";
                  return;
                }
              }
            }
          }
        }
      }
      if (this.allData && this.allData.id) {
        this.update({
          ...this.allData,
          parents: this.invoice.parents,
          ref_doc_nr: this.invoice.ref_doc_nr,
          items: this.itemsList,
          payments: this.paymentsList,
        });
      } else {
        this.create({
          ...this.allData,
          ref_doc_nr: this.invoice.ref_doc_nr,
          items: this.itemsList,
          payments: this.paymentsList,
        });
      }
    },
    saveAdditional(additional) {
      this.update({ ...this.invoice, additional: additional });
    },
    update(newData, back = false) {
      InvoiceDataService.update(newData.id, newData)
        .then(() => {
          if (newData.invoice_type != 'Proform' && this.allData.invoice_status == "Done") {
            this.eInvoice(newData);
          } else {
            this.refreshData(newData.id);
            this.$store.commit("app/showAlert", {
              type: "success",
              message: "Podaci su promenjeni!",
            });
            this.headKey++;
            if (back) this.$router.go(-1);
          }
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    updateComment2(newData) {
      InvoiceDataService.update(newData.id, newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Podaci su promenjeni!",
          });
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    async create(newData) {
      InvoiceDataService.create(newData)
        .then((res) => {
          this.dataInvoice["id"] = res.data["data"]["id"];
          this.refreshData(res.data["data"]["id"]);
          // console.log(this.allData.invoice_status)
          if (newData.invoice_type != 'Proform' && this.allData.invoice_status == "Done") {
            this.eInvoice(res.data["data"]);
          } else {
            this.$store.commit("app/showAlert", {
              type: "success",
              message: "Dokument je kreiran!",
            });
            //Navigate back
            // this.$router.go(-1);
          }
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    deleteData() {
      if (this.invoice.id) {
        if (this.invoice.invoice_status == "Done") {
          invoiceUtils.stornoDocument(this.invoice, true);
          // this.$router.go(-1);
        } else {
          invoiceUtils.deleteDocument(this.invoice, true);
          // this.$router.go(-1);
        }
      } else {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: "Morate prvo snimiti dokument",
        });
      }
    },
    createNormal() {
      if (this.invoice.invoice_type == "Proform") {
        this.$store.commit("app/showModal", {
          modal: SelectAdvance,
          params: {
            title: "Predačun: " + this.allData.doc_nr,
            // proformNr: this.allData.doc_nr,
            proform: this.invoice,
            showForm: this.invoice != null && this.invoice.reservation_id,
          },
        });
      } else {
        if (this.invoice != null && this.invoice.reservation_id) {
          this.$store.commit("app/showModal", {
            modal: SelectAdvance,
            params: {
              title: "Avans: " + this.allData.doc_nr,
              // proformNr: null,
              proform: this.invoice,
              showForm: this.invoice != null && this.invoice.reservation_id,
            },
          });
        } else {
          this.createDoc("Normal");
        }
      }
    },
    async createAdvance(index) {
      // console.log(`createAdvance ${index}`);
      if (this.paymentsList.length > 0) {
        if (this.invoice != null && this.invoice.reservation_id) {
          const payment = index >= 0 ? [this.paymentsList[index]] : this.paymentsList;
          let newData = {
            ref_doc_nr: this.allData.doc_nr,
            exchange: this.allData.exchange,
            exchange_value: this.allData.exchange_value,
            exchange_date: this.allData.exchange_date,
            price: payment[0].payment, //this.tableTotalSum["sum"]["sum"],
            price_exc: payment[0].payment_exc, //this.tableTotalSum["sum"]["sum_exc"],
            /// if index is not null, then we are creating advance with payment on that index
            payments: payment,
          };
          invoiceUtils.createAdvanceWithReservation(
            this.invoice.reservation_id,
            "Advance",
            newData
          );
        } else {
          this.createDoc("Advance", index >= 0 ? this.paymentsList[index].id : null);
        }
      } else {
        this.$store.commit("app/showAlert", {
          type: "warning",
          message: "Da bi kreirali avans neophodno je da prvo unesete uplate!",
        });
      }
    },
    async createDoc(type, pid) {
      // this.allData["status"] = "New";
      // console.log(`createDoc ${type} ${pid}`);
      if (pid) {
        invoiceUtils.createDoc(this.allData.id, type, [pid]);
      } else {
        invoiceUtils.createDoc(this.allData.id, type);
      }
    },
    async addItem(newItem) {
      if (!this.allData.exchange_value) {
        this.$store.commit("app/showAlert", {
          type: "warning",
          message: "Morate izabrati kurs!",
        });
        return;
      }
      if (this.$route.params.type == "Normal" && !this.invoice.additional) {
        this.invoice.additional = {};
      }
      await invoiceUtils.addItem(
        newItem,
        this.itemData,
        this.itemsList,
        this.taxRates,
        this.sell_type,
        this.$route.params.type == "Normal"
          ? typeof this.invoice.additional == "string"
            ? JSON.parse(this.invoice.additional)
            : this.invoice.additional
          : null
      );
      invoiceUtils.updateSum(this.tableSum, this.itemsList, this.taxRates);
      invoiceUtils.updateTotalSum(
        this.tableTotalSum,
        this.paymentsList,
        this.tableSum
      );
      // this.nextKey++;
      this.tableKey++;
    },
    removeItem(index) {
      invoiceUtils.removeItem(index, this.itemsList);
      invoiceUtils.updateSum(this.tableSum, this.itemsList, this.taxRates);
      invoiceUtils.updateTotalSum(
        this.tableTotalSum,
        this.paymentsList,
        this.tableSum
      );
      // this.nextKey++;
      // this.tableKey++;
    },
    addPaymentAndSave(payment) {
      this.addPayment(payment, true);
    },
    addPayment(payment, save = false) {
      if (!payment.statement_date || !payment.payment || !payment.payment_exc) {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: "Morate uneti podatke",
        });
        return;
      }
      if (payment.statement_date > new Date().toISOString().substring(0, 10)) {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: "Datum uplate ne moze biti u buducnosti",
        });
        return;
      }
      this.paymentsList.push({ ...payment });
      this.paymentData = {
        statement_nr: null,
        statement_date: new Date().toISOString().substring(0, 10),
        payment: null,
        payment_exc: null,
      };
      this.paymentData["statement_nr"] = null;
      this.paymentData["payment"] = null;
      this.paymentData["payment_exc"] = null;
      this.paymentFormKey++;
      invoiceUtils.updateTotalSum(
        this.tableTotalSum,
        this.paymentsList,
        this.tableSum
      );
      this.toggle = false;
      if (save && this.dataInvoice.invoice_status == "Done") {
        this.update({
          ...this.allData,
          items: this.itemsList,
          payments: this.paymentsList,
        });
      }
    },
    removePayment(index) {
      utils.showDialog(
        index,
        "Da li ste sigurni da zelite da obrisete uplatu?",
        "Brisanje uplate",
        () => {
          this.paymentsList.splice(index, 1);
          invoiceUtils.updateTotalSum(
            this.tableTotalSum,
            this.paymentsList,
            this.tableSum
          );
          if (this.dataInvoice.invoice_status == "Done") {
            this.update({
              ...this.allData,
              items: this.itemsList,
              payments: this.paymentsList,
            });
          }
          this.$store.commit("app/hideModal");
        }
      );
    },
    getFromNBS() {
      exchangeUtils.getFromNBS(this.allData.exchange_date.replaceAll("-", ""));
    },
    refreshData(id) {
      this.load = true;
      InvoiceDataService.get(id).then(async (res) => {
        this.invoice = res.data;
        this.itemsList = [];
        if (this.invoice.invoice_items) {
          for (const item of this.invoice.invoice_items) {
            await this.addItem(item);
          }
        }
        this.paymentsList = [];
        if (this.invoice.invoice_payments) {
          //this.invoice.invoice_payments.forEach((item) => 
          for (const item of this.invoice.invoice_payments) {
            this.addPayment(item);
          }
          //);
        }
        this.allData.invoice_status = this.invoice.invoice_status;
        this.dataInvoiceHead.invoice_status = this.invoice.invoice_status;
        // Is sef enabled
        this.sef = this.invoice.Customer.pib ? await SefEnabledCustomersDataService.get(this.invoice.Customer.pib ?? '0') : {data: {}};
        this.nextKey++;
        this.excKey++;
        this.load = false;
      });
    },
    print() {
      if (this.invoice.id) {
        invoiceUtils.print(this.$route.params.type, this.invoice.id);
      } else {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: "Morate prvo snimiti dokument",
        });
      }
    },
    eInvoice(invData) {
      if (this.invoice.saop_num) {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: `Dokument je vec prosledjen na SAOP. SAOP broj ${this.invoice.saop_num}`,
        });
        return;
      }
      const toSoapId = invData ? invData.id : this.invoice.id;
      if (!toSoapId) {
        this.$store.commit("app/showAlert", {
          type: "danger",
          message: `Račun nije snimljen, ne može biti prosledjen na saop`,
        });
        return;
      }
      utils.showDialog(
        this.invoice,
        `Da li ste sigurni da želite da pošaljete dokument ${toSoapId} na SAOP?`,
        "Prosledjivanje na SAOP",
        () => {
          InvoiceDataService.toSaop(toSoapId)
            .then((res) => {
              this.refreshData(toSoapId);
              if (res.data.message) {
                this.$store.commit("app/hideModal");
                this.$store.commit("app/showAlert", {
                  type: "danger",
                  message: res.data.message,
                });
              } else {
                this.$store.commit("app/hideModal");
                // this.refreshData(toSoapId);
                this.$store.commit("app/showAlert", {
                  type: "success",
                  message: "Dokument je prosledjen na SAOP!",
                });
              }
            })
            .catch((e) => {
              this.refreshData(toSoapId);
              this.$store.commit("app/hideModal");
              this.$store.commit("app/showAlert", {
                type: "danger",
                message: e,
              });
            });
          // console.log(`Slanje na saop ${this.invoice.id}`);
          // this.$store.commit("app/hideModal");
        },
        () => {
          this.allData.invoice_status = "New";
          this.dataInvoiceHead.invoice_status = "New";
          // this.saveData();
          InvoiceDataService.update(this.invoice.id, { invoice_status: 'New' }).then(() => {
            this.refreshData(this.invoice.id);
            this.$store.commit("app/hideModal");
          })
        }
      );
    },
    fiscal() {
      utils.showDialog(
        this.invoice,
        "Da li ste sigurni da želite odštampate fiskalni račun?",
        "Fiskalni račun",
        () => {
          // console.log(`Fiskalni racun ${this.invoice.id}`);
          this.$store.commit("app/hideModal");
        }
      );
      /// If invoice eInvoice_num is null, show modal with payment types
      // if (!this.invoice.eInvoice_num) {
      //   this.$store.commit("app/showModal", {
      //     modal: PaymentTypesForm,
      //     params: {
      //       parent: "invoices",
      //       title: "Fiskalizacija",
      //       invoice: this.invoice,
      //     },
      //   });
      // } else {
      //   this.$store.commit("app/showAlert", {
      //     type: "danger",
      //     message: `Dokument je vec prosledjen na fiskalnu kasu. Fiskalni broj ${this.invoice.eInvoice_num}`,
      //   });
      // }
    },
  },
  async mounted() {
    if (this.$route.params.id != 0) {
      this.refreshData(this.$route.params.id);
    } else {
      this.invoice = {
        invoice_status: "New",
        invoice_type: this.$route.params.type,
        additional: JSON.parse("{}"),
        doc_date: new Date().toISOString().substring(0, 10),
        doc_nr: "",
      };
    }

    CustomersDataService.getAllForList().then((res) => {
      this.customersList = res.data.rows;
      this.nextKey++;
    });

    ArticlessDataService.getAllForList().then((res) => {
      this.articlesList = res.data.rows ? res.data.rows : res.data;
      this.nextKey++;
    });

    StationsDataService.getAllForList().then((res) => {
      this.stationsList = res.data.rows;
      this.nextKey++;
    });
  },
  created() { },
  beforeRouteEnter(to, from, next) {
    utils.confirmNo();
    next();
  },
  watch: {
    $route(to, from) {
      if (to.name == from.name && to.params.id != 0) {
        this.refreshData(to.params.id);
      }
    },
  },
};
</script>

<style scoped>
.page {
  max-width: 1200px;
  margin-left: 30px;
}

.company {
  font-size: smaller;
}

input[type="number"] {
  text-align: right;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>